.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(1, minmax(280px, 1fr));
  gap: 1rem;
}

/* Media Queries */

// Determine # of card columns by viewport resolution

@media (min-width: 1280px) {
  .experience-cards-div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1920px) {
  .experience-cards-div {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 2560px) {
  .experience-cards-div {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 3200px) {
  .experience-cards-div {
    grid-template-columns: repeat(5, 1fr);
  }
}
