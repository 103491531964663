@import "../../_globalColor";

.talk-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem 1rem;
}

// TODO Move this?
.button-div {
  display: flex;
  margin-top: 20px;
}
